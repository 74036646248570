import '../css/CardProfile.css';
import profile from '../assets/profile.jpg';
import React, { useState } from "react";

const CardProfile = () => {
    const [active, setActive] = useState(false);

    const openWa = () => {
        setActive(!active);
        setTimeout(() => {
            window.open("https://bit.ly/chat_via_wa_here", "_blank");
            setActive(false); // Mengatur kembali state setelah klik
        }, 100);
    };

    return (
        <div className='card-profile-container'>
            <div className='card-profile'>
                <img className='img-profile' src={profile} alt="Profile" />
                <div className='card-profile-content'>
                    <div className='title-profile'>Random Developer</div>
                    <div className='name-profile'>Yzshlxn Dev</div>
                    <div className='bio-profile'>Membuat game adalah hobi saya hwehwe.</div>
                    <button
                        className={active ? 'button-contact-profile button-contact-profile-pressed' : 'button-contact-profile'}
                        onClick={openWa}>
                        Contact Me
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CardProfile;
