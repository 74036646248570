import React, { useState } from 'react';
import '../css/CardItemItchIO.css';

const CardItemItchIO = ({ gameId }) => {
  const iframeSrc = `https://itch.io/embed/${gameId}`;
  const fallbackLink = `https://itch.io/${gameId}`;
  const [active, setActive] = useState(false);

  const handleMouseEnter = () => setActive(true);
  const handleMouseLeave = () => setActive(false);

  return (
    <div
      className={`card-item-itchio ${active ? 'active' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='iframe-container-itchio'>
        <iframe
          src={iframeSrc}
          allowFullScreen
        >
          <a href={fallbackLink}>Play on itch.io</a>
        </iframe>
      </div>
    </div>
  );
};

export default CardItemItchIO;
