import React from 'react';
import CardProfile from './components/CardProfile';
import Carousel, { CarouselItem } from '../src/components/Carousel';
import CardItemApp from './components/CardItemApp';
import CardItemItchIO from './components/CardItemItchIO';
import { TROLL_GAME_ID, SAVE_THE_TREES_ID } from '../src/constants';
import './App.css';

function App() {
  return (
    <div className='app-container'>
      <header className='app-header'>
        <h1 className='neonText'>Mobile Games</h1>
      </header>

      <main className='carousel-section'>
        <Carousel>
          <CarouselItem>
            <CardItemApp
              imgSrc='https://play-lh.googleusercontent.com/WYjDbNdFsTJW4sf1IK3QljefMzWaj99-59gnJXIqwh6zrYhybss9N60S-ksKO_la4UE=w1052-h592'
              category='Adventure'
              name="Troll Game: Where's My Head?"
              downloadUrlAndroid='https://play.google.com/store/apps/details?id=com.yashlan.memeadventure'
              downloadUrlIOS=''  // Tambahkan URL iOS jika ada
            />
          </CarouselItem>

          <CarouselItem>
            <CardItemApp
              imgSrc='https://play-lh.googleusercontent.com/M_EDGVrjohiwR_ZZSxi424cTNi1dhFHQLFoUN-c4dcMm3a86ycOrgncLizZI8StGGMV2=w1052-h592'
              category='Music'
              name='Pianika Lite - Basuri'
              downloadUrlAndroid='https://play.google.com/store/apps/details?id=com.yashlan.pianika_lite'
              downloadUrlIOS='https://apps.apple.com/id/app/pianika-lite-basuri/id6449957632'  // Tambahkan URL iOS jika ada
            />
          </CarouselItem>

          <CarouselItem>
            <CardItemApp
              imgSrc='https://play-lh.googleusercontent.com/rLp_TKGTfkX5gG5mmolcMdxYuqJCfkZmchc1KCFCkj7MelJmZucn-PLyV2kuD1zmwA=w526-h296'
              category='Music'
              name='Pianika'
              downloadUrlAndroid='https://play.google.com/store/apps/details?id=com.yashlan.Pianica'
              downloadUrlIOS='https://apps.apple.com/id/app/pianika/id6449969214'  // Tambahkan URL iOS jika ada
            />
          </CarouselItem>
        </Carousel>
      </main>

      <h1 className='neonText'>Browser Games</h1>

      <main className='carousel-section'>
        <Carousel>
          <CarouselItem>
            <CardItemItchIO
              gameId={TROLL_GAME_ID}
            />
          </CarouselItem>

          <CarouselItem>
            <CardItemItchIO
              gameId={SAVE_THE_TREES_ID}
            />
          </CarouselItem>
        </Carousel>
      </main>


      <footer className='app-footer'>
        <h1 className='neonText'>About</h1>
        <div className='card-wrapper'>
          <CardProfile />
        </div>
      </footer>
    </div>
  );
}

export default App;
