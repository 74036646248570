import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import "../css/Carousel.css";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children, timeToNext = 3000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false); // State to track mouse pressed
  const [startPos, setStartPos] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [transition, setTransition] = useState("transform 0.3s ease-in-out");

  const carouselRef = useRef();

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  // Auto scroll carousel
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused && !dragging) {
        updateIndex(activeIndex + 1);
      }
    }, timeToNext);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex, paused, dragging, timeToNext]);

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  // Start drag only if clicking on a carousel item
  const handleMouseDown = (e) => {
    if (e.target.closest(".carousel-item")) {
      setDragging(true);
      setIsMouseDown(true); // Mouse is down
      setStartPos(e.clientX);
      setTransition("none"); // Disable transition during dragging
    }
  };

  const handleTouchStart = (e) => {
    if (e.target.closest(".carousel-item")) {
      setDragging(true);
      setStartPos(e.touches[0].clientX);
      setTransition("none");
    }
  };

  // Dragging
  const handleMouseMove = (e) => {
    if (!dragging || !isMouseDown) return;
    const diff = e.clientX - startPos;
    setTranslate(diff);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;
    const diff = e.touches[0].clientX - startPos;
    setTranslate(diff);
  };

  // Drag end
  const handleMouseUp = () => {
    setIsMouseDown(false);
    setDragging(false);
    setTransition("transform 0.3s ease-in-out");

    if (translate < -100) {
      updateIndex(activeIndex + 1);
    } else if (translate > 100) {
      updateIndex(activeIndex - 1);
    }
    setTranslate(0); // Reset translate after drag ends
  };

  const handleTouchEnd = () => {
    setDragging(false);
    setTransition("transform 0.3s ease-in-out");

    if (translate < -100) {
      updateIndex(activeIndex + 1);
    } else if (translate > 100) {
      updateIndex(activeIndex - 1);
    }
    setTranslate(0);
  };

  // Reset dragging if the mouse leaves the carousel area
  const handleMouseLeave = () => {
    if (dragging) {
      setDragging(false);
      setIsMouseDown(false);
      setTranslate(0);
      setTransition("transform 0.3s ease-in-out");
    }
  };

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)} // Resume autoplay when mouse leaves
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={carouselRef}
      style={{ cursor: dragging ? "grabbing" : "grab" }}
    >
      <div
        className="inner"
        style={{
          transform: `translateX(calc(-${activeIndex * 100}% + ${translate}px))`,
          transition: transition,
        }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="indicators">
        {React.Children.map(children, (_, index) => (
          <button
            key={index}
            className={`indicator ${index === activeIndex ? 'active' : ''}`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
