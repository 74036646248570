import React, { useState } from 'react';
import '../css/CardItemApp.css';

const CardItemApp = ({ imgSrc, category, name, downloadUrlAndroid, downloadUrlIOS }) => {
    const [active, setActive] = useState(null);

    const handleDownload = (url, platform) => {
        if (url) {
            setActive(platform);
            setTimeout(() => {
                window.open(url, "_blank");
                setActive(null);
            }, 100);
        }
    };

    const isIOSAvailable = downloadUrlIOS && downloadUrlIOS.trim() !== '';

    return (
        <div className='card-item'>
            <img className='card-item-img' src={imgSrc} alt={name} />
            <div className='card-item-details'>
                <div className='card-item-name'>{name}</div>
                <div className='card-item-category'>{category}</div>
            </div>
            <div className='card-item-buttons'>
                <button
                    className={active === 'android' ? 'card-button card-button-android card-button-pressed-android' : 'card-button card-button-android'}
                    onClick={() => handleDownload(downloadUrlAndroid, 'android')}>
                    Download for Android
                </button>
                <button
                    className={isIOSAvailable ? (active === 'ios' ? 'card-button card-button-ios card-button-pressed' : 'card-button card-button-ios') : 'card-button card-button-ios card-button-disabled'}
                    onClick={() => isIOSAvailable && handleDownload(downloadUrlIOS, 'ios')}
                    disabled={!isIOSAvailable}
                >
                    {isIOSAvailable ? 'Download for iOS' : 'Not Available for iOS'}
                </button>
            </div>
        </div>
    );
};

export default CardItemApp;
